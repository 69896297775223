<template>
  <div class="carousel-wrapper">
    <div class="header-container flex between">
      <h3 v-if="display_title">
        {{ display_title }}
      </h3>
      <div v-if="link">
        <NuxtLink :to="localePath(link.href)" class="view-link" :aria-label="`${link.title} ${display_title}`">
          {{ link.title }}
        </NuxtLink>
      </div>
    </div>
    <div :class="['scroll-content-wrapper', { event: isEvent }]">
      <div :class="['scroll-wrapper', { 'few-items': lessThanFour }]">
        <PostItem v-for="item in mappedPosts" :key="item.url" :post="item" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { extractItems } from '~/util/contentstack/csHelpers';
import { CTSPost } from '~/types/contentstack';

const localePath = useLocalePath();

const props = defineProps({
  postsConnection: {
    type: Object,
    required: true,
  },
  // eslint-disable-next-line vue/prop-name-casing
  display_title: {
    type: String,
    default: '',
  },
  link: {
    type: Object,
    default: undefined,
  },
});

const mappedPosts = computed<CTSPost[]>(() => extractItems(props.postsConnection));
const isEvent = computed<boolean>(
  () => !!mappedPosts.value?.find((item: CTSPost) => item?.post_type?.post_type === 'event')
);
const lessThanFour = computed<boolean>(() => mappedPosts.value?.length < 4);
</script>
<style lang="scss" scoped>
.carousel-wrapper {
  border-bottom: 1px solid $color-neutral-cool-200;
  padding-bottom: 3rem;
}
.header-container {
  align-items: center;
  margin-bottom: 1rem;
  a {
    font-weight: 500;
    color: $color-neutral-cool-900;
  }
  .view-link:hover {
    color: $color-primary-600;
  }
  @include local-mixins.tablet_and_mobile {
    margin: 0 16px;
    margin-bottom: 0.5rem;
  }
}
.scroll-content-wrapper {
  overflow-x: auto;
  overflow-y: clip;
  .scroll-wrapper {
    display: flex;
    justify-content: space-between;
    &.few-items {
      justify-content: space-evenly;
    }
  }
  :deep(.post-wrapper) {
    width: #{local-functions.rem-calc(320)};
    &:not(:last-child) {
      margin-right: 2rem;
    }
    .header-image {
      width: #{local-functions.rem-calc(320)};
    }
    @include local-mixins.tablet_and_mobile {
      width: #{local-functions.rem-calc(280)};
      &:not(:last-child) {
        margin-right: 1rem;
      }
      &:last-child {
        width: #{local-functions.rem-calc(296)};
        padding-right: 1rem;
      }
      .header-image {
        width: #{local-functions.rem-calc(280)};
      }
    }
  }
  @include local-mixins.xxl_and_lower {
    .scroll-wrapper {
      margin: 0 1rem;
    }
    &.event {
      overflow-x: revert;
      .scroll-wrapper {
        flex-direction: column;
      }
      .post-wrapper {
        width: 100%;
        margin-right: 0;
      }
      .post-wrapper:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }
}
</style>
